<template>
  <div>
    <div class="d-flex gap-1 align-start px-8 flex-wrap flex-lg-nowrap">
      <div class="col-12">
        <!-- <v-alert dense prominent type="info">
          <v-row align="center">
            <v-col class="grow"> Import your Clients now. </v-col>
            <v-col class="shrink">
              <v-btn class="primary" depressed>Import</v-btn>
            </v-col>
          </v-row>
        </v-alert> -->
        <v-card class="my-5 box-shadow pa-5 ribbon-container" outlined>
          <span class="font-weight-bold title mr-5 mb-3 d-flex align-center"
            ><v-icon class="mr-1">mdi-account-group</v-icon>Client List</span
          >
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              dense
              single-line
              hide-details
              background-color="white"
            ></v-text-field>

            <vue-json-to-csv :json-data="clients">
              <v-btn small class="ml-3 info" depressed>
                CSV <v-icon>mdi-download</v-icon>
              </v-btn>
            </vue-json-to-csv>
          </v-card-title>
          <v-data-table
            v-if="clients.length"
            :headers="headers"
            :items="clients"
            :search="search"
          >
            <template v-slot:[`item.view`]="{ item }">
              <v-btn
                depressed
                x-small
                class="primary mr-2"
                :class="item.name"
                @click="setSelectedClient(item)"
              >
                View Plans
              </v-btn>
            </template>
          </v-data-table>
          <v-data-table
            v-else
            :headers="[]"
            :items="[]"
            :search="search"
            loading
            loading-text="Loading Clients..."
          >
          </v-data-table>
        </v-card>
      </div>
    </div>

    <v-dialog v-model="viewPlanDialog" max-width="900" scrollable persistent>
      <close-button @click="viewPlanDialog = false" overflow />
      <v-card>
        <v-card-text class="py-3">
          <span class="font-weight-bold title mr-5 mb-3 d-flex align-center"
            ><v-icon class="mr-1">mdi-account</v-icon>{{ selectedClient?.name }}</span
          >
          <v-card class="mb-5 primary" outlined>
            <v-card-text>
              <span class="font-weight-bold subtitle-1 mr-5 mb-3 d-flex align-center white--text"
                >Meal Plans</span
              >

              <v-data-table
                v-if="selectedClient"
                :headers="mealPlanHeaders"
                :items="viewMealPlans"
                dense
              >
                <template v-slot:[`item.link`]="{ item }">
                  <v-btn
                    depressed
                    x-small
                    class="primary mr-2"
                    :disabled="!item.link"
                    @click="goToLink(item.link)"
                  >
                    Ebook Link
                  </v-btn>
                </template>
                <template v-slot:[`item.options`]="{ item }">
                  <v-btn
                    depressed
                    x-small
                    class="info mr-2"
                    :class="item"
                    dense
                    @click="openOptions(item)"
                  >
                  Open Options
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>

          <v-card outlined class="custom-purple">
            <v-card-text>
              <span class="font-weight-bold subtitle-1 mr-5 mb-3 d-flex align-center white--text"
                >Recipe eBooks</span
              >

              <v-data-table
                v-if="selectedClient"
                :headers="recipePlanHeaders"
                :items="viewRecipePlans"
              >
                <template v-slot:[`item.link`]="{ item }">
                  <v-btn
                    depressed
                    x-small
                    class="primary mr-2"
                    @click="goToLink(item.link)"
                  >
                    Ebook Link
                  </v-btn>
                </template>
                <template v-slot:[`item.options`]="{ item }">
                  <v-btn
                    depressed
                    x-small
                    class="info mr-2"
                    :class="item"
                    @click="openOptions(item)"
                  >
                  Open Options
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewOptions" max-width="750" scrollable persistent>
      <close-button @click="viewOptions = false" overflow />
      <v-card>
      <v-card-title>
        <div class="pr-10 font-weight-bold subtitle-2" :style="{ lineHeight: '1.2' }">{{ selectedPlan?.name }}</div>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-card class="mb-5" outlined>
          <v-simple-table dense>
            <template #default>
              <thead>
                <tr>
                  <th>Hide Nutrition Info</th>
                  <th>Hide Nutrition Tags</th>
                  <th>Hide Meal Tags</th>
                  <th>Deactivate eBook</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="selectedPlan?.recipeplan">
                  <td class="text-center">
                    <v-switch
                      @change="toggleTags"
                      v-model="selectedPlan.promoData.hideEnergy"
                      class="mt-0 pt-0"
                      hide-details
                      dense
                    ></v-switch>
                  </td>
                  <td class="text-center">
                    <v-switch
                      @change="toggleTags"
                      v-model="selectedPlan.promoData.hideMealTags"
                      class="mt-0 pt-0"
                      hide-details
                      dense
                    ></v-switch>
                  </td>
                  <td class="text-center">
                    <v-switch
                      @change="toggleTags"
                      v-model="selectedPlan.promoData.hideNutriTags"
                      class="mt-0 pt-0"
                      hide-details
                      dense
                    ></v-switch>
                  </td>
                  <td>
                    <v-btn class="primary" x-small depressed v-if="selectedPlan.isDeactivated" @click="activateEbook">
                      Activate eBook
                    </v-btn>
                    <v-btn color="error" x-small depressed v-else @click="deactivateEbook">
                      Deactivate eBook
                    </v-btn>
                  </td>
                </tr>
                <tr v-else>
                  <td class="text-center">
                    <v-switch
                      @change="toggleEnergy"
                      v-model="selectedPlan.hideEnergy"
                      hide-details
                      dense
                    ></v-switch>
                  </td>
                  <td class="text-center">
                    <v-switch
                      @change="toggleMealTags"
                      v-model="selectedPlan.hideMealTags"
                      hide-details
                      dense
                    ></v-switch>
                  </td>
                  <td class="text-center">
                    <v-switch
                      @change="toggleNutriTags"
                      v-model="selectedPlan.hideNutriTags"
                      hide-details
                      dense
                    ></v-switch>
                  </td>
                  <td>
                    <v-btn class="primary" x-small depressed v-if="selectedPlan.isDeactivated" @click="activateEbook">
                      Activate eBook
                    </v-btn>
                    <v-btn class="error" x-small depressed v-else @click="deactivateEbook">
                      Deactivate eBook
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";
import { mapState, mapActions } from "vuex";
import Vue from 'vue'

export default {
  name: "Clients",
  data() {
    return {
      goals: [
        { text: "My goal is to lose weight", value: "g1" },
        { text: "My goal is to reduce my blood glucose levels", value: "g2" },
        { text: "My goal is to manage my IBS", value: "g3" },
        { text: "My goal is to increase my fibre", value: "g4" },
        {
          text: "My goal is to eat appropriately after weight loss surgery",
          value: "g5",
        },
        { text: "My goal is to improve my heart health", value: "g6" },
        { text: "My goal is to manage my allergies/intolerance", value: "g7" },
        { text: "My goal is to gain weight", value: "g8" },
        { text: "My goal is to maintain my weight", value: "g9" },
      ],
      search: "",
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Age",
          value: "age",
        },
        {
          text: "Goal",
          value: "goal",
        },
        {
          text: "BMI (kg/m2)",
          value: "bmi",
        },
        {
          text: "PAL",
          value: "pal",
        },
        {
          text: "Meal Pattern",
          value: "mealPattern",
        },
        {
          text: "No. of Meal Plans eBooks",
          value: "mealPlans",
          align: "center"
        },
        {
          text: "No. of Recipe Plans eBooks",
          value: "recipePlans",
          align: "center"
        },
        {
          text: "Last Plan Created",
          value: "lastCreated",
        },

        {
          text: "View Plans",
          align: "start",
          value: "view",
        },
      ],
      mealPlanHeaders: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Nutrition Tags",
          value: "nutritionTags",
        },
        {
          text: "Meal Tags",
          value: "mealTags",
        },
        {
          text: "Ingredient Tags",
          value: "ingredientTags",
        },
        {
          text: "eBook Link",
          align: "start",
          value: "link",
        },
        {
          text: "eBook Options",
          align: "start",
          value: "options",
        },
      ],
      recipePlanHeaders: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Nutrition Tags",
          value: "nutritionTags",
        },
        {
          text: "Meal Tags",
          value: "mealTags",
        },
        {
          text: "Ingredient Tags",
          value: "ingredientTags",
        },
        {
          text: "eBook Link",
          align: "start",
          value: "link",
        },
        {
          text: "eBook Options",
          align: "start",
          value: "options",
        },
      ],
      clients: [],
      selectedPlan: {},
      viewOptions: false,
      selectedClient: {},
      viewPlanDialog: false,
    };
  },
  components: {
    VueJsonToCsv,
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      allPlans: (state) => state.mealplans.plans,
      allFolders: (state) => state.mealplans.folders,
    }),

    folders() {
      // return this.allFolders.filter((folder) => folder?.promo);
      return this.allFolders;
    },

    mealPlans() {
      return this.allPlans.filter((plan) => !plan?.recipeplan);
    },

    recipePlans() {
      let plans = this.allPlans.filter((plan) => plan?.recipeplan);

      return plans.map((p) => {
        return {
         ...p, 
         promoData: p?.linkDetails?.user?.promoData || {}
        }
      })
    },

    viewMealPlans() {
      if (!this.selectedClient) return [];

      let myMealPlans = this.mealPlans.filter(
        (m) => m.folderId == this.selectedClient?.folderId
      );

      return myMealPlans.map((m) => {
        let mealTags = [];
        let nutritionTags = [];
        let ingredientTags = [];


        if (m?.mealTags?.length)
          mealTags = m.mealTags.map((t) => {
            if (this.$store.getters["tags/checkIfUnpublish"](t));
            return this.$store.getters["tags/getName"](t);
          });

        if (m?.nutritionTags?.length)
          nutritionTags = m.nutritionTags.map((t) => {
            if (this.$store.getters["nutritiontags/checkIfUnpublish"](t))
              return this.$store.getters["nutritiontags/tagData"](t).name;
          });

        if (m?.ingredientTags) ingredientTags = m.ingredientTags;

        return {
          ...m,
          mealTags,
          ref: m?.ref,
          name: m.name,
          options: true,
          nutritionTags,
          ingredientTags,
          recipeplan: false,
          link: m?.mealplanLink || "",
          hideEnergy: m?.hideEnergy || false,
          hideMealTags: m?.hideMealTags || false,
          isDeactivated: m?.isDeactivated || false,
          hideNutriTags: m?.hideNutriTags || false,
        };
      });
    },

    viewRecipePlans() {
      if (!this.selectedClient) return [];

      let myRecipePlans = this.recipePlans.filter(
        (m) => m.folderId == this.selectedClient?.folderId
      );

      return myRecipePlans.map((m) => {
        let mealTags = [];
        let nutritionTags = [];
        let ingredientTags = [];

        if (m?.mealTags?.length)
          mealTags = m.mealTags.map((t) => {
            if (this.$store.getters["tags/checkIfUnpublish"](t));
            return this.$store.getters["tags/getName"](t);
          });

        if (m?.nutritionTags?.length)
          nutritionTags = m.nutritionTags.map((t) => {
            if (this.$store.getters["nutritiontags/checkIfUnpublish"](t))
              return this.$store.getters["nutritiontags/tagData"](t).name;
          });

        if (m?.ingredientTags) ingredientTags = m.ingredientTags;

        // const link = `${window.origin}/promoebookpage/${this.removeSpace(
        //   `${this.user.firstName}${this.user.lastName}`
        // )}/${this.removeSpace(this.selectedClient.name.replace(/#/gi, ""))}?i=${m?.id}`;

        const link = `http://mealguide.pro/promoebookpage/${this.removeSpace(
          `${this.user.firstName}${this.user.lastName}`
        )}/${this.removeSpace(this.selectedClient.name.replace(/#/gi, ""))}?i=${m?.id}`;

        return {
          link,
          mealTags,
          id: m?.id,
          ref: m?.ref,
          name: m.name,
          options: true,
          nutritionTags,
          ingredientTags,
          recipeplan: true,
          promoData: m?.promoData,
          linkDetails: m?.linkDetails,
          isDeactivated: m?.isDeactivated || false,
        };
      });
    },
  },
  methods: {
    ...mapActions("mealplans", ["getPlans", "getFolders", 'updateRecipeLink']),
    goToLink(link) {
      console.log(link);
      setTimeout(() => {
        window.open(link, "_blank");
      });
    },
    removeSpace(text) {
      return text.replace(/\s/g, "%20");
    },
    getGoalValue(val) {
      return this.goals.find((g) => g.value == val);
    },

    getClients() {
      if (!this.folders.length) return [];

      if (!this.allPlans.length) return [];

      return this.folders.map((folder) => {
        let mealplans = this.allPlans.filter((p) => p.folderId == folder?.id);

        let mealplan = mealplans[mealplans.length - 1];

        let myMealPlans = this.mealPlans.filter((m) => m.folderId == folder?.id);

        let myRecipePlans = this.recipePlans.filter((m) => m.folderId == folder?.id);

        let mealPattern = [];

        if (folder?.mainMeals) mealPattern = [...folder?.mainMeals];

        if (folder?.snacks) {
          for (let s = 1; s <= folder?.snacks; s++) {
            mealPattern.push(`Snack ${s}`);
          }
        }

        this.clients.push({
          view: true,
          name: folder.client,
          email: folder.email,
          folderId: folder.id,
          age: folder.age,
          goal: this.getGoalValue(folder.goal).text,
          bmi: Math.round(
            folder.weight / ((folder.height / 100) * (folder.height / 100))
          ),
          pal: folder.physicalActivityLevel.name,
          mealPattern,
          mealPlans: myMealPlans.length,
          recipePlans: myRecipePlans.length,
          lastCreated: moment(mealplan?.createdAt || new Date()).format("MMM DD YYYY"),
        });
      });
    },

    setSelectedClient(client) {
      this.selectedClient = client;
      this.viewPlanDialog = true;
    },

    updateMealPlan() {
      let plan = this.mealPlans.find(p => p.id == this.selectedPlan.id)

      let index = this.mealPlans.indexOf(plan)

      Vue.set(this.mealPlans, index, this.selectedPlan)
    },

    updateRecipePlan() {
      let plan = this.recipePlans.find(p => p.id == this.selectedPlan.id)

      let index = this.recipePlans.indexOf(plan)

      Vue.set(this.recipePlans, index, this.selectedPlan)
    },

    toggleEnergy() {
      this.$store.dispatch('mealplans/updateMealField', {
        value: this.selectedPlan.hideEnergy,
        field: 'hideEnergy',
        meal: this.selectedPlan,
      });

      this.updateMealPlan()
    },

    toggleMealTags() {
      this.$store.dispatch('mealplans/updateMealField', {
        value: this.selectedPlan.hideMealTags,
        field: 'hideMealTags',
        meal: this.selectedPlan,
      });

      this.updateMealPlan()
    },

    toggleNutriTags() {
      this.$store.dispatch('mealplans/updateMealField', {
        value: this.selectedPlan.hideNutriTags,
        field: 'hideNutriTags',
        meal: this.selectedPlan,
      });

      this.updateMealPlan()
    },

    // FOR RECIPE PLAN
    toggleTags() {

      const { recipes, user, mainMeals, meals, goal } = this.selectedPlan.linkDetails

      const details = {
        user: {
          ...user,
          promoData: this.selectedPlan.promoData
        },
        goal,
        meals,
        recipes,
        mainMeals,
      }

      this.updateRecipeLink({
        plan: this.selectedPlan,
        details,
        silent: true
      })
    },

    openOptions(payload) {
      this.selectedPlan = payload
      this.viewOptions = true
    },

    activateEbook() {
      this.selectedPlan.isDeactivated = false

      this.$store.dispatch('mealplans/updateMealField', {
        value: false,
        field: 'isDeactivated',
        meal: this.selectedPlan,
        silent: true
      });

    },

    deactivateEbook() {
      this.selectedPlan.isDeactivated = true

      this.$store.dispatch('mealplans/updateMealField', {
        value: true,
        field: 'isDeactivated',
        meal: this.selectedPlan,
        silent: true
      });

    }
  },

  mounted() {
    Promise.resolve(this.getPlans()).then(async () => {
      await this.getFolders();
      await this.$store.dispatch("tags/getTags");
      await this.$store.dispatch("nutritiontags/getTags");
      this.getClients();
    });
  },
};
</script>
<style scoped>
  .custom-purple {
    background-color: #463f57 !important;
    border-color: #463f57 !important;
  }
</style>